@import "@/assets/style/_index.scss";



























































































































































































































































































































































































































































































































































.footer.new-order {
    background-color: #fff;
    > .total-amount {
        flex: 2 2 1%;
        text-align: right;
        padding-right: $padding-small;
    }
}

.free-freight {
    text-align: center;
    min-height: inherit !important;
    padding: $padding-small;
    background-color: mix($color-red, #fff, 40%);
    color: #fff;
}
